import { createApp } from "vue";
import moment from 'moment';
import store from './store';
import App from "./App.vue";
import router from "./router";

router.beforeEach((to, from, next) => {
  const tokenExpiration = sessionStorage.getItem('loginTokenExpiration');
  const loginToken = sessionStorage.getItem('loginToken');
  const currentTime = String(moment().toDate());
  const checkExpired = moment(currentTime).isAfter(tokenExpiration);

  // Commit token from SessionStorage to Store State
  store.commit('loadToken', loginToken);
  // Reroute to login page
  if (to.name !== 'Login' // Not on login page and token doesn't exist
  && (!tokenExpiration
  || tokenExpiration === null
  || tokenExpiration === 'undefined'
  || !loginToken
  || loginToken === null
  || loginToken === 'undefined')) {
    next({ name: 'Login' });
  } else if (to.name !== 'Login' // Not on login page, token exists but expired
  && tokenExpiration
  && tokenExpiration !== null
  && tokenExpiration !== 'undefined'
  && loginToken
  && loginToken !== null
  && loginToken !== 'undefined'
  && checkExpired) {
    next({ name: 'Login' });
    store.commit(
      'updateWarning',
      'Your session has expired, please login again to use Course Contact Form.',
    );
  } else { // If token exists and not expired continue using app
    next();
  }
});

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
