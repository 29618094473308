import { createStore } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import router from './router';

const serverUrl = `${process.env.VUE_APP_URL_PROTOCOL}://${process.env.VUE_APP_API_URL}/api/v1`;
const routes = {
  authenticate: `${serverUrl}/authenticate`,
  user: `${serverUrl}/user`,
  feedback: `${serverUrl}/feedback`,
};

export default createStore({
  state: {
    token: null,
    userInfo: null,
    error: null,
    success: null,
    warning: null,
    packageVersion: process.env.PACKAGE_VERSION || '1.0.0',
  },
  mutations: {
    loadToken(state, token) {
      state.token = token;
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    resetToken(state) {
      state.token = null;
      state.userInfo = null;
      sessionStorage.removeItem('loginTokenExpiration');
      sessionStorage.removeItem('loginToken');
    },
    updateSuccess(state, success) {
      state.success = success;
    },
    updateError(state, error) {
      state.error = error;
    },
    updateWarning(state, warning) {
      state.warning = warning;
    },
  },
  actions: {
    userLogin({ commit }, { netid, password }) {
      axios.post(routes.authenticate, {
        netid,
        password,
      }).then((response) => {
        const { token } = response.data;
        sessionStorage.setItem('loginToken', token);
        commit('loadToken', token);
        const tokenExpires = moment().add(30, 'm').toDate();
        sessionStorage.setItem('loginTokenExpiration', tokenExpires);
        commit('updateSuccess', 'Login Successfull!');
        router.push('/');
      }).catch((error) => {
        if (error) {
          commit('updateError', error.response.data.error);
        }
      });
    },
    async getUserData({ commit, state }) {
      const config = {
        headers: {
          'x-access-token': state.token,
          'Content-Type': 'application/json',
        },
      };
      try {
        const { data: userData } = await axios.get(routes.user, config);
        const { courses, uin } = userData;
        commit('updateUserInfo', {
          enrolledCourses: courses,
          uin
        }); 
      } catch (error) {
        console.error("Error fetching the user data", error);
        commit('updateUserInfo', {
          enrolledCourses: [],
          uin: ''
        });
      }
    },
    submitFeedback({ commit, state }, formData) {
      const feedbackURL = `${routes.feedback}`;
      const config = {
        headers: {
          'x-access-token': state.token,
          'Content-Type': 'application/json',
        },
      };
      const uin = state.userInfo.uin;
      const {crn, comments, category, courseTitle} = formData;
      const data = {
        uin,
        crn,
        comments,
        category,
        courseTitle,
        email_sent: "0"
      };
      axios.post(feedbackURL, data, config)
        .then(({data}) => {
            commit('updateSuccess', data.message);
        }).catch(({response}) => {
          commit('updateError', response.data);
          console.log(response.data);
        });
    }
  },
  getters: {
    appVersion(state) {
      return state.packageVersion;
    },
  },
});
