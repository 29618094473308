<template>
  <div class="app">
    <header class="r-grid" role="banner">
      <a class="app-logo-link" href="https://www.uic.edu/">
        <span class="app-logo"><img :src="circleLogoSVG" alt="UIC Logo"/></span>
      </a>
      <div class="app-user" v-if="token">
        <router-link to="/login" @click="logout()">Logout</router-link>
      </div>
    </header>
    <div class="banner"> <h1 class="r-grid"> Department Course Contact Form </h1></div>
    <alerts v-on:remove="removeAlert" :alerts="alerts"></alerts>
    <main class="body r-grid">
      <router-view></router-view>
    </main>
    
    <footer>
    <div class="r-grid">
      <span>
        © {{ new Date().getFullYear() }} The Board of Trustees of the University of Illinois
      </span>
      <br/>
      <span>UIC Department Course Contact Form v{{ $store.getters.appVersion }}</span>
    </div>
  </footer>
  </div>
</template>

<script>
import circleLogoSVG from '@/components/svg/logo-circle.svg';
import Alerts from '@/components/Alerts.vue';

export default {
  name: 'App',
  components: {
    Alerts,
  },
  data() {
    return {
      circleLogoSVG
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    alerts() {
      const alerts = [
        {
          text: this.$store.state.success,
          type: 'success'
        },
        {
          text: this.$store.state.error,
          type: 'error'
        },
        {
          text: this.$store.state.warning,
          type: 'warning'
        },
      ];
      const filteredAlerts = alerts.filter(alert => alert.text);
      return filteredAlerts;
    }
  },
  watch: {
    alerts(newAlerts) {
      newAlerts.forEach(alert => {
        setTimeout(() => {
          this.removeAlert(alert.type);
        }, 2000);
      });
    },
  },
  methods: {
    logout() {
      if (this.$store.state.token !== null) {
        this.$store.commit('resetToken');
      }
    },
    removeAlert(type) {
      if(type === "success") {
        this.$store.commit('updateSuccess', null);
      } else if(type === "warning") {
        this.$store.commit('updateWarning', null);
      } else {
        this.$store.commit('updateError', null);
      }
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

input {
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 5px 4px;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
}

header {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
}

h1 {
  padding: 0 16px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 0;
}

h2 {
  font-size: 20px;
  line-height: 40px;
  margin: 2px 0;
  padding: 0;
  font-weight: 400;
}

button {
  width: 100%;
  text-align: center;
  background-color: #001E62;
  color: white;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 46px;
  height: 48px;
  font-size: 16px;
  border: none;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    background-color: #001648;
  }
  &.disabled {
    background-color: grey;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:focus-visible {
    outline: 2px solid #2196F3;
  }
}


footer {
  padding: 30px 0;
  text-align: center;
  border-top: 1px #f6f6f6 solid;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.app {
  width: 100%;
  margin: auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  position: relative;
  min-height: 100vh;
}

.banner {
  width: 100%;
  background: #001E62;
}

.r-grid {
  padding: 0 16px;
  max-width: 600px;
  margin: 0 auto;
}
.container {
  background-color: #f6f6f6;
  padding: 16px;
}

.body {
  padding: 16px 16px 120px 16px;
}

.asterisk {
  color: #D50032;
}

.app-user {
  margin-left: auto;
  padding: 10px;
  display: flex;
  align-items: center;
}

.app-logo {
  padding: 2px;
  & > img {
    height: 100%;
  }

  &-link {
    text-decoration: none;
    padding: 4px 0;
  }
}

.pipe {
  margin: 0 8px;
}

</style>
