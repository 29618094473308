<template>
  <div class="alert-container">
    <div
      class="alert r-grid"
      v-for="alert, index in alerts"
      :key="index"
      :class="{
        'alert-error': alert.type === 'error',
        'alert-success': alert.type === 'success',
        'alert-warning': alert.type === 'warning',
      }"
      aria-live="assertive"
      @click="$emit('remove', alert.type)"
    >
      <p class="alert-msg">
        {{ alert.text }}
      </p>
      <img class="alert-close" :src="closeIcon"/>
    </div>
  </div>
</template>

<script>
import closeIcon from '@/components/svg/close-icon.svg';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Alerts',
  props: ['alerts'],
  data() {
    return {
      closeIcon
    };
  },
  mounted() {},
  computed: {
    
  },
  methods: {},
}
</script>

<style lang="scss" scoped>

.alert {
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in;
  box-sizing: border-box;

  &-container {
    position: relative;
    height: 4px;
  }
  &-error {
    background-color: lighten(red, 25%);
  }
  &-success {
    background-color: lighten(greenyellow, 25%);
  }
  &-warning {
    background-color: yellow;
  }
  &-msg {
    font-size: 16px;
    margin: 0;
  }
  &-close {
    width: 12px;
    height: 12px;
    margin-inline-start: auto;
  }
  &:not(:first-of-type) {
    border-top: 1px solid #fff;
  }
}

</style>