<template>
  <div class="login">
    <h2> Login </h2>
    <form
      class="login-form container"
      v-on:submit.prevent="userLogin()"
      aria-label="course feedback login"
    >
      <div class="login-form-element">
        <label class="login-form-label" for="username">NetID</label>
        <input v-model="netid" type="text" id="username" name="username"/>
      </div>

      <div class="login-form-element">
        <label class="login-form-label" for="password">Password</label>
        <input v-model="password" type="password" id="password" name="password"/>
        
      </div>
      <button class="login-submit" type="submit"> Login </button>
    </form>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  props: {},
  data() {
    return {
        netid: null,
        password: null,
    };
  },
  mounted() {
    this.$store.commit('resetToken');
  },
  methods: {
    userLogin() {
      const payload = {
        netid: this.netid,
        password: this.password,
      };
      this.$store.dispatch('userLogin', payload);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.login {
  font-size: 16px;
  text-align: start;
  &-form {
    display: flex;
    flex-direction: column;
    &-element {
      margin: 10px 0;
      text-align: left;
    }
    &-label {
      font-size: 16px;
      margin: 5px 0 0;
    }
  }
  & > h2 {
    // margin-left: 16px;
  }
}


</style>
