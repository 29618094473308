<template>
  <div class="review" v-if="courses">
    
    <div v-if="courses.length == 0">
      <h2 class="asterisk container"> Cannot submit the course feedback as there are no registered courses for this user </h2>
    </div>
    
    <form
      v-else
      class="review-form container"
      @submit.prevent="submitFeedback()"
    >
      <div class="review-form-element">
        <label class="review-course-label" for="review-course">
          Please select a course from the list of your registered courses below.
          Your comment, question, or feedback will be sent directly to the academic department for that course
          <span class="asterisk">*</span>
        </label>
        <select
          class="review-course-input"
          id="review-course"
          v-model="selectedCourse"
          aria-required="true"
          required
        >
          <option disabled value="">Please select a course </option>
          <option
            v-for="course in courses"
            :key="course.course_number"
            :value="{
              'courseTitle': getCourseTitle(course),
              'crn': course.crn
            }">
            {{ getCourseTitle(course) }}
          </option>
        </select>
      </div>
      <div class="review-form-element">
        <label class="review-course-label" for="review-category">
          What is your feedback concerning?
          <span class="asterisk">*</span>
        </label>
        <select
          class="review-course-input"
          id="review-category"
          v-model="selectedCategory"
          aria-required="true"
          required
        >
          <option disabled value="">Please select a category</option>
          <option value="Course Content">Course Content</option>
          <option value="Professor/Instructor">Professor/Instructor</option>
          <option value="Teaching Assistant">Teaching Assistant</option>
          <option value="Room/Lab">Room/Lab</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div class="review-form-element" v-if="selectedCategory == 'Other'">
        <label class="review-course-label" for="review-category-detail">
          Please Specify Other
          <span class="asterisk">*</span>
        </label>
        <input
          class="review-course-input"
          id="review-category-detail"
          v-model="categoryDetail"
          aria-required="true"
          required
          maxlength="20"
        />
        <span class="review-course-guides asterisk" v-if="categoryDetail.length > 0"> {{ 20 - categoryDetail.length }} characters remaining </span>
      </div>

      <div class="review-form-element">
        <label class="review-course-label" for="review-text">
          Comments
          <span class="asterisk">*</span>
        </label>
        <textarea
          v-model="comments"
          aria-required="true"
          required
          class="review-text-input"
          id="review-text"
          name="review-text"
          cols="50"
          maxlength="500"
          rows="5"
        >
        </textarea>
        <span class="review-course-guides asterisk" v-if="comments.length > 0"> {{ 500 - comments.length }} characters remaining </span>
      </div>
      <button class="review-submit" type="submit">
        Submit Course Feedback
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "ReviewForm",
  props: {},
  created() {
    this.$store.dispatch("getUserData");
  },
  data: function () {
    return {
      selectedCourse: "",
      selectedCategory: "",
      categoryDetail: "",
      comments: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo || {};
    },
    courses() {
      return this.userInfo.enrolledCourses || [];
    },
  },
  methods: {
    getCourseTitle(course) {
      return `${course.subject_code} ${course.course_number}`;
    },
    submitFeedback() {
      if(this.selectedCourse == "" || this.selectedCourse.crn == "" || this.selectedCourse.courseTitle == "") {
        this.$store.commit("updateWarning", "Please select a course");
        return;
      }
      if(this.comments == "") {
        this.$store.commit("updateWarning", "Please add review comments for the course");
        return;
      }
      if(this.selectedCategory == "") {
        this.$store.commit("updateWarning", "Please select a category");
        return;
      }
      let category = this.selectedCategory;
      if(category == 'Other') {
        category += ` (${this.categoryDetail})`;
      }
      this.$store.dispatch("submitFeedback", {
        crn: this.selectedCourse?.crn,
        courseTitle: this.selectedCourse?.courseTitle,
        category,
        comments: this.comments
      });
      this.selectedCourse = '';
      this.selectedCategory = '';
      this.comments = '';
      this.categoryDetail = '';
    }
  }
};
</script>

<style lang="scss" scoped>

select, textarea {
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 5px 4px;
  font-size: 16px;
  margin: 10px 0;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.review {
  font-size: 16px;
  text-align: start;
  &-form {
    display: flex;
    flex-direction: column;
    &-element {
      margin: 10px 0;
      &--inline {
        display: flex;
        align-items: center;
      }
    }
  }

  &-course {
    &-label {
      display: block;
      font-size: 16px;
      margin: 5px 0 0;
    }

    &-input {
      padding: 5px;
      width: 100%;
      font-size: 16px;
    }

    &-guides {
      font-size: 12px;
      font-weight: 600;
      padding-inline-start: 8px;
    }
  }

  &-text-input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    font-family: sans-serif;
  }
}
</style>
