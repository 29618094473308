import { createRouter, createWebHistory } from "vue-router";
import Login from "./components/Login";
import ReviewForm from "./components/ReviewForm";

const routes = [
  { path: "/login", name: "Login", component: Login },
  { path: "/", name: "ReviewForm", component: ReviewForm },
  {
    path: "/:catchAll(.*)",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
